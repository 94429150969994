<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ form }}</pre> -->
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <CInput v-model="form[`name`]" :label="`Name`" :placeholder="`Name`" />
        </CCol>

        <CCol sm="6"></CCol>

        <CCol sm="6" v-for="lang in languages" :key="`name_${lang}`">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>

      <!-- <CRow v-for="lang in languages" :key="`description_${lang}`">
        <CCol sm="12">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>-->

      <CRow>
        <CCol sm="6">
          <CInput v-model="form.product_id" label="Product id" placeholder="Product id" />
        </CCol>

        <CCol sm="6">
          <CInput
            v-model="form.count_download"
            label="Count download"
            placeholder="Count download"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>

        <CCol sm="6">
          <AppSlect v-model="form.tag" :options="tagOptions" label="Tag" />
        </CCol>

        <CCol sm="6">
          <AppSlect v-model="form.type" :options="typeOptions" label="Type" />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.order" label="Order" placeholder="Order" />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>

        <CCol sm="3">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show in slider"
            :checked="form.show_in_slider"
            @update:checked="value => (form.show_in_slider = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Free"
            :checked="form.free"
            @update:checked="value => (form.free = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Recent"
            :checked="form.recent"
            @update:checked="value => (form.recent = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Recommended"
            :checked="form.recommended"
            @update:checked="value => (form.recommended = value)"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.image && form.image.file"
            name="image"
            :allowDelete="false"
            :image="form.image && form.image.url ? form.image.url : form.image"
            @input="value => handleChangeFile({ value, key: 'image' })"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.imageHorizontal && form.imageHorizontal.file"
            name="Image Horizontal"
            :allowDelete="false"
            :image="form.imageHorizontal && form.imageHorizontal.url ? form.imageHorizontal.url : form.imageHorizontal"
            @input="value => handleChangeFile({ value, key: 'imageHorizontal' })"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.preset && form.preset.file"
            name="File Keynote"
            type="file"
            accept="*"
            :image="
              form.keynote && form.keynote.url ? form.keynote.url : form.keynote
            "
            @input="value => handleChangeFile({ value, key: 'keynote' })"
            @delete="value => deleteFile('keynote')"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.powerpoint && form.powerpoint.file"
            name="File PowerPoint"
            type="file"
            accept="*"
            :isShow="isShow"
            :image="
              form.powerpoint && form.powerpoint.url
                ? form.powerpoint.url
                : form.powerpoint
            "
            @input="value => handleChangeFile({ value, key: 'powerpoint' })"
            @delete="value => deleteFile('powerpoint')"
          />
        </CCol>

        <CCol sm="6">
          <FileLoaderUrl
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="
              form.canva && form.canva.url != null ? form.canva.url : form.canva
            "
            name="File Canva"
            type="file"
            :isShow="isShow"
            @input="
              value => handleChangeFile({ value, key: 'canva', type: 'url' })
            "
            @delete="value => deleteFile('canva')"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.other && form.other.file"
            name="File Other"
            type="file"
            accept="*"
            :isShow="isShow"
            :image="form.other && form.other.url ? form.other.url : form.other"
            @input="value => handleChangeFile({ value, key: 'other' })"
            @delete="value => deleteFile('other')"
          />
        </CCol>

        <!-- <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.font && form.font.file"
            name="File Font first"
            type="file"
            accept="*"
            :isShow="isShow"
            :image="form.font && form.font.url ? form.font.url : form.font"
            @input="value => handleChangeFile({ value, key: 'font' })"
            @delete="value => deleteFile('font')"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.fontSecond && form.fontSecond.file"
            name="File Font second"
            type="file"
            accept="*"
            :isShow="isShow"
            :image="
              form.fontSecond && form.fontSecond.url
                ? form.fontSecond.url
                : form.fontSecond
            "
            @input="value => handleChangeFile({ value, key: 'fontSecond' })"
            @delete="value => deleteFile('fontSecond')"
          />
        </CCol>-->
      </CRow>

      <CRow>
        <CCol sm="12">
          <ModalPresets
            :isShow="isShow"
            :data="form.presets"
            @change="value => (form.presets = value)"
            @delete="deletePreset"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import ModalPresets from "./ModalPresets";

import FileLoader from "@/components/root/FileLoader";
import FileLoaderUrl from "@/components/root/FileLoaderUrl";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    ModalPresets,
    FileLoader,
    FileLoaderUrl
  },

  data: () => ({
    form: {
      name: null,
      product_id: null,
      count_download: 0,
      presets: [],
      show: true,
      show_in_slider: false,
      free: false,
      recommended: true,
      recent: false,
      created_at: null,
      keynote: null,
      powerpoint: null,
      other: null,
      canva: null,
      font: null,
      fontSecond: null,
      image: null,
      imageHorizontal: null,
      tag: null,
      type: null,
      order: 0
    },

    languages: ["en", "ru", "fr", "es", "de", "it"],

    types: ["9/16", "1/1", "4/3", "3/4", "16/9"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data
          ? "Edit Filteroom collection"
          : "Add Filteroom collection";
      }
    },

    createdAt() {
      // return this.form[`created_at`];
      // return moment(this.form[`created_at`])
      //   .subtract("hours", 3)
      //   .format("YYYY-MM-DD hh:mm:ss");
    },

    tagOptions() {
      let res = [];

      for (let item of this.addictionsData.tag) {
        res.push({ value: item.id, label: item.name_en });
      }

      return res;
    },

    typeOptions() {
      let res = [];

      for (let item of this.types) {
        res.push({ value: item, label: item });
      }

      return res;
    }
  },

  created() {
    // for (let lang of this.languages) {
    //   this.form[`name_${lang}`] = null;
    // }

    for (let lang of this.languages) {
      this.form[`description_${lang}`] = null;
    }
  },

  methods: {
    handleChangeFile({ value, key, type = "file" }) {
      console.log("handleChangeFile", this.form);

      this.form[key] = {
        type: "upload",
        file: value || null,
        url: type == "file" ? URL.createObjectURL(value) : value
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    },

    // deleteFile(key) {
    //   console.log("key", key);

    //   this.handlerDeleteFile(key);
    // },

    deletePreset(index) {
      console.log(index);

      if (this.form.presets[index] && this.form.presets[index].id)
        this.handlerDeletePreset({
          id: this.form.presets[index].id,
          cb: () => {
            this.form.presets.splice(index, 1);
          }
        });
      else this.form.presets.splice(index, 1);
    },

    handleDeleteFife(data) {
      console.log("data", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
